window.dzsprx_self_options = {};

(function () {
  function dzsparallaxer(options) {
    var defaults = {
      settings_mode: "scroll", // scroll or mouse or mouse_body or oneelement
      mode_scroll: "normal", // -- normal or fromtop
      easing: "easeIn", // -- easeIn or easeOutQuad or easeInOutSine
      animation_duration: "20", // -- animation duration in ms
      direction: "normal", // -- normal or reverse
      js_breakout: "off", // -- if on it will try to breakout of the container and cover fullwidth
      breakout_fix: "off", // -- if you are using a div breakout this will add classes and tagnames back
      is_fullscreen: "off", // -- if this is fullscreen parallaxer, then we can just follow
      settings_movexaftermouse: "off", // -- if on the parallax will move after the mouse
      init_delay: "0", // -- a delay on which to start the init function
      init_functional_delay: "0", // -- a delay on which to start the parallax movement
      settings_substract_from_th: 0, // -- if you only want to show some part of the image you can subtract pixels from the total height
      settings_detect_out_of_screen: true, // -- detect if the parallax is outside the viewable area and not animate handleframe
      init_functional_remove_delay_on_scroll: "off", // -- remove the delay on which to start the parallax movement
      settings_makeFunctional: false,
      settings_scrollTop_is_another_element_top: null, // -- an object on which the scroll is actually simulated into an elements top position
      settings_clip_height_is_window_height: false, // -- replace the clip height to the window height
      settings_listen_to_object_scroll_top: null, // -- replace the scroll top listening with a value from the outside
      settings_mode_oneelement_max_offset: "20",
      simple_parallaxer_convert_simple_img_to_bg_if_possible: "on",
    };

    var o = Object.assign({}, defaults, options);
    o.settings_mode_oneelement_max_offset = parseInt(
      o.settings_mode_oneelement_max_offset,
      10
    );

    Math.easeIn = function (t, b, c, d) {
      return -c * (t /= d) * (t - 2) + b;
    };

    Math.easeOutQuad = function (t, b, c, d) {
      t /= d;
      return -c * t * (t - 2) + b;
    };

    Math.easeInOutSine = function (t, b, c, d) {
      return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b;
    };

    document.querySelectorAll(".dzsparallaxer").forEach(function (cthis) {
      var _theTarget = null,
        _blackOverlay = null,
        _fadeouttarget = null;

      var nritems = 0,
        tobeloaded = 0,
        i = 0;

      var tw = 0,
        th = 0,
        ch = 0,
        cw = 0,
        ww = 0,
        wh = 0,
        last_wh = 0,
        last_cthis_ot = 0,
        initialheight = 0;

      var int_calculate_dims = 0;

      var duration_viy = 0;

      var target_viy = 0,
        target_vix = 0,
        target_bo = 0;

      var begin_viy = 0,
        begin_vix = 0,
        begin_bo = 0;

      var finish_viy = 0,
        finish_vix = 0,
        finish_bo = 0;

      var change_viy = 0,
        change_vix = 0,
        change_bo = 0;

      var backup_duration_viy = 0;
      var api_outer_update_func = null,
        _scrollTop_is_another_element_top = null;

      var st = 0,
        vi_y = 0,
        bo_o = 0,
        cthis_ot = 0;

      var lazyloading_setsource = "";

      var started = false,
        debug_var = false;

      var animator_objects_arr = null;

      var stop_enter_frame = false,
        sw_suspend_functional = false,
        sw_stop_enter_frame = false,
        sw_out_of_display = false;

      var init_delay = 0,
        init_functional_delay = 0;

      var inter_debug_func = 0,
        inter_suspend_enter_frame = 0,
        inter_scroll_from_resize = 0,
        inter_recheck_dims = 0;

      var responsive_reference_width = 0,
        responsive_optimal_height = 0;

      init_delay = Number(o.init_delay);
      init_functional_delay = Number(o.init_functional_delay);

      if (init_delay) {
        setTimeout(init, init_delay);
      } else {
        init();
      }

      function init() {
        if (o.settings_makeFunctional == true) {
          var allowed = false;

          var url = document.URL;
          var urlStart = url.indexOf("://") + 3;
          var urlEnd = url.indexOf("/", urlStart);
          var domain = url.substring(urlStart, urlEnd);
          if (
            domain.indexOf("l") > -1 &&
            domain.indexOf("c") > -1 &&
            domain.indexOf("o") > -1 &&
            domain.indexOf("l") > -1 &&
            domain.indexOf("a") > -1 &&
            domain.indexOf("h") > -1
          ) {
            allowed = true;
          }
          if (
            domain.indexOf("d") > -1 &&
            domain.indexOf("i") > -1 &&
            domain.indexOf("g") > -1 &&
            domain.indexOf("d") > -1 &&
            domain.indexOf("z") > -1 &&
            domain.indexOf("s") > -1
          ) {
            allowed = true;
          }
          if (
            domain.indexOf("o") > -1 &&
            domain.indexOf("z") > -1 &&
            domain.indexOf("e") > -1 &&
            domain.indexOf("h") > -1 &&
            domain.indexOf("t") > -1
          ) {
            allowed = true;
          }
          if (
            domain.indexOf("e") > -1 &&
            domain.indexOf("v") > -1 &&
            domain.indexOf("n") > -1 &&
            domain.indexOf("a") > -1 &&
            domain.indexOf("t") > -1
          ) {
            allowed = true;
          }
          if (allowed == false) {
            return;
          }
        }

        if (o.settings_scrollTop_is_another_element_top) {
          _scrollTop_is_another_element_top =
            o.settings_scrollTop_is_another_element_top;
        }

        _theTarget = cthis.querySelector(".dzsparallaxer--target");
        if (cthis.querySelector(".dzsparallaxer--blackoverlay")) {
          _blackOverlay = cthis.querySelector(".dzsparallaxer--blackoverlay");
        }
        if (cthis.querySelector(".dzsparallaxer--fadeouttarget")) {
          _fadeouttarget = cthis.querySelector(".dzsparallaxer--fadeouttarget");
        }

        if (!cthis.classList.contains("wait-readyall")) {
          setTimeout(function () {
            duration_viy = Number(o.animation_duration);
          }, 300);
        }

        cthis.classList.add("mode-" + o.settings_mode);

        ch = cthis.offsetHeight;
        if (o.settings_movexaftermouse == "on") {
          cw = cthis.offsetWidth;
        }

        if (_theTarget) {
          th = _theTarget.offsetHeight;
          if (o.settings_movexaftermouse == "on") {
            tw = _theTarget.offsetWidth;
          }
        }
        if (o.settings_substract_from_th) {
          th -= o.settings_substract_from_th;
        }

        initialheight = ch;

        if (o.breakout_fix == "2") {
          console.info(cthis.previousElementSibling);
        }

        if (cthis.getAttribute("data-responsive-reference-width")) {
          responsive_reference_width = Number(
            cthis.getAttribute("data-responsive-reference-width")
          );
        }
        if (cthis.getAttribute("data-responsive-optimal-height")) {
          responsive_optimal_height = Number(
            cthis.getAttribute("data-responsive-optimal-height")
          );
        }

        if (cthis.querySelector(".dzsprxseparator--bigcurvedline")) {
          cthis.querySelector(".dzsprxseparator--bigcurvedline").innerHTML +=
            '<svg class="display-block" width="100%"  viewBox="0 0 2500 100" preserveAspectRatio="none" ><path class="color-bg" fill="#FFFFFF" d="M2500,100H0c0,0-24.414-1.029,0-6.411c112.872-24.882,2648.299-14.37,2522.026-76.495L2500,100z"/></svg>';
        }

        if (cthis.querySelector(".dzsprxseparator--2triangles")) {
          cthis.querySelector(".dzsprxseparator--2triangles").innerHTML +=
            '<svg class="display-block" width="100%"  viewBox="0 0 1500 100" preserveAspectRatio="none" ><polygon class="color-bg" fill="#FFFFFF" points="1500,100 0,100 0,0 750,100 1500,0 "/></svg>';
        }
        if (cthis.querySelector(".dzsprxseparator--triangle")) {
          cthis.querySelector(".dzsprxseparator--triangle").innerHTML +=
            '<svg class="display-block" width="100%"  viewBox="0 0 2200 100" preserveAspectRatio="none" ><polyline class="color-bg" fill="#FFFFFF" points="2200,100 0,100 0,0 2200,100 "/></svg>';
        }

        if (cthis) {
          cthis.api_set_scrollTop_is_another_element_top = function (arg) {
            _scrollTop_is_another_element_top = arg;
          };
        }

        if (is_touch_device()) {
          cthis.classList.add("is-touch");
        }
        if (is_mobile()) {
          cthis.classList.add("is-mobile");
        }

        if (cthis.querySelector(".divimage") || cthis.querySelector("img")) {
          var _loadTarget = cthis.querySelector(".divimage, img");
          if (_loadTarget.getAttribute("data-src")) {
            lazyloading_setsource = _loadTarget.getAttribute("data-src");
            window.addEventListener("scroll", handle_scroll);
            handle_scroll();
          } else {
            init_start();
          }
        } else {
          init_start();
        }
      }

      function init_start() {
        if (started) {
          return;
        }
        started = true;

        if (is_ie11()) {
          cthis.classList.add("is-ie-11");
        }

        window.addEventListener("resize", handle_resize);
        handle_resize();

        inter_recheck_dims = setInterval(function () {
          handle_resize(null, {
            call_from: "autocheck",
          });
        }, 2000);

        if (cthis.classList.contains("wait-readyall")) {
          setTimeout(function () {
            handle_scroll();
          }, 700);
        }

        setTimeout(function () {
          cthis.classList.add("dzsprx-readyall");
          handle_scroll();

          if (cthis.classList.contains("wait-readyall")) {
            duration_viy = Number(o.animation_duration);
          }
        }, 1000);

        if (cthis.querySelector("*[data-parallaxanimation]")) {
          cthis
            .querySelectorAll("*[data-parallaxanimation]")
            .forEach(function (_t) {
              if (_t.getAttribute("data-parallaxanimation")) {
                if (animator_objects_arr == null) {
                  animator_objects_arr = [];
                }

                animator_objects_arr.push(_t);

                var aux = _t.getAttribute("data-parallaxanimation");
                aux = "window.aux_opts2 = " + aux;
                aux = aux.replace(/'/g, '"');
                try {
                  eval(aux);
                } catch (err) {
                  console.info(aux, err);
                  window.aux_opts2 = null;
                }

                if (window.aux_opts2) {
                  for (i = 0; i < window.aux_opts2.length; i++) {
                    if (!isNaN(Number(window.aux_opts2[i].initial))) {
                      window.aux_opts2[i].initial = Number(
                        window.aux_opts2[i].initial
                      );
                    }
                    if (!isNaN(Number(window.aux_opts2[i].mid))) {
                      window.aux_opts2[i].mid = Number(window.aux_opts2[i].mid);
                    }
                    if (!isNaN(Number(window.aux_opts2[i].final))) {
                      window.aux_opts2[i].final = Number(
                        window.aux_opts2[i].final
                      );
                    }
                  }
                  _t.dataset.parallaxOptions = window.aux_opts2;
                }
              }
            });
        }

        if (init_functional_delay) {
          sw_suspend_functional = true;
          setTimeout(function () {
            sw_suspend_functional = false;
          }, init_functional_delay);
        }

        if (!cthis.classList.contains("simple-parallax")) {
          handle_frame();
        } else {
          _theTarget.parentNode.classList.add("simple-parallax-inner");
          if (
            o.simple_parallaxer_convert_simple_img_to_bg_if_possible == "on" &&
            _theTarget.getAttribute("data-src") &&
            _theTarget.children.length == 0
          ) {
            _theTarget.parentNode.classList.add("is-image");
          }

          if (simple_parallaxer_max_offset > 0) {
            handle_frame();
          }
        }

        inter_debug_func = setInterval(debug_func, 1000);

        setTimeout(function () {
          // Finish.y = -300;
        }, 1500);

        if (cthis.classList.contains("use-loading")) {
          if (
            cthis.querySelector(".divimage") ||
            cthis.children[0].tagName === "IMG"
          ) {
            if (cthis.querySelector(".divimage")) {
              if (lazyloading_setsource) {
                cthis.querySelector(".divimage").style.backgroundImage =
                  "url(" + lazyloading_setsource + ")";
              }
              var _loadTarget_src = String(
                cthis
                  .querySelector(".divimage")
                  .style.backgroundImage.split('"')
              )[1];
              if (_loadTarget_src == undefined) {
                _loadTarget_src = String(
                  cthis
                    .querySelector(".divimage")
                    .style.backgroundImage.split("url(")
                )[1];
                _loadTarget_src = String(_loadTarget_src.split(")"))[0];
              }
              var _loadTarget = new Image();

              _loadTarget.onload = function (e) {
                cthis.classList.add("loaded");
              };

              _loadTarget.src = _loadTarget_src;
            }
          } else {
            cthis.classList.add("loaded");
          }

          setTimeout(function () {
            cthis.classList.add("loaded");
            calculate_dims();
          }, 10000);
        }

        cthis.api_set_update_func = function (arg) {
          api_outer_update_func = arg;
        };
        cthis.api_handle_scroll = handle_scroll;
        cthis.api_destroy = destroy;
        cthis.api_destroy_listeners = destroy_listeners;

        if (o.settings_mode == "scroll" || o.settings_mode == "oneelement") {
          window.removeEventListener("scroll", handle_scroll);
          window.addEventListener("scroll", handle_scroll);
          handle_scroll();
          setTimeout(handle_scroll, 1000);

          if (document && document.addEventListener) {
            document.addEventListener("touchmove", handle_mousemove, false);
          }
        }

        if (
          o.settings_mode == "mouse_body" ||
          o.settings_movexaftermouse == "on"
        ) {
          document.addEventListener("mousemove", handle_mousemove);
        }
      }

      function destroy() {
        api_outer_update_func = null;
        stop_enter_frame = true;
        api_outer_update_func = null;
        window.removeEventListener("scroll", handle_scroll);
        if (document && document.addEventListener) {
          document.removeEventListener("touchmove", handle_mousemove, false);
        }
      }

      function debug_func() {
        debug_var = true;
      }

      function destroy_listeners() {
        clearInterval(inter_debug_func);
        clearInterval(inter_recheck_dims);
        sw_stop_enter_frame = true;
      }

      function handle_resize(e, pargs) {
        cw = cthis.offsetWidth;
        ww = window.innerWidth;
        wh = window.innerHeight;

        var margs = {
          call_from: "event",
        };

        if (pargs) {
          margs = Object.assign(margs, pargs);
        }

        if (started === false) {
          return;
        }

        if (o.settings_mode == "oneelement") {
          var last_translate = cthis.style.transform;
          cthis.style.transform = "translate3d(0,0,0)";
        }

        cthis_ot = parseInt(cthis.getBoundingClientRect().top, 10);

        if (margs.call_from == "autocheck") {
          if (
            Math.abs(last_wh - wh) < 4 &&
            Math.abs(last_cthis_ot - cthis_ot) < 4
          ) {
            if (o.settings_mode == "oneelement") {
              cthis.style.transform = last_translate;
            }
            return false;
          }
        }
        last_wh = wh;
        last_cthis_ot = cthis_ot;

        if (responsive_reference_width && responsive_optimal_height) {
          if (cw < responsive_reference_width) {
            var aux =
              (cw / responsive_reference_width) * responsive_optimal_height;
            cthis.style.height = aux + "px";
          } else {
            cthis.style.height = responsive_optimal_height + "px";
          }
        }

        if (cw < 760) {
          cthis.classList.add("under-760");
        } else {
          cthis.classList.remove("under-760");
        }
        if (cw < 500) {
          cthis.classList.add("under-500");
        } else {
          cthis.classList.remove("under-500");
        }

        if (int_calculate_dims) {
          clearTimeout(int_calculate_dims);
        }

        int_calculate_dims = setTimeout(calculate_dims, 700);

        if (o.js_breakout == "on") {
          cthis.style.width = ww + "px";
          cthis.style.marginLeft = "0";

          if (cthis.getBoundingClientRect().left > 0) {
            cthis.style.marginLeft =
              "-" + cthis.getBoundingClientRect().left + "px";
          }
        }

        if (o.is_fullscreen == "on") {
        }
      }

      function calculate_dims() {
        ch = cthis.offsetHeight;
        th = _theTarget.offsetHeight;
        wh = window.innerHeight;

        if (o.settings_substract_from_th) {
          th -= o.settings_substract_from_th;
        }

        if (o.settings_clip_height_is_window_height) {
          ch = window.innerHeight;
        }

        if (
          cthis.classList.contains("allbody") == false &&
          cthis.classList.contains("dzsparallaxer---window-height") == false &&
          responsive_reference_width == 0
        ) {
          if (th <= initialheight && th > 0) {
            if (
              o.settings_mode != "oneelement" &&
              cthis.classList.contains("do-not-set-js-height") == false &&
              cthis.classList.contains("height-is-based-on-content") == false
            ) {
              cthis.style.height = th + "px";
            }
            ch = cthis.offsetHeight;
            if (is_ie() && version_ie() <= 10) {
              _theTarget.style.top = "0";
            } else {
              _theTarget.style.transform = "translate3d(0," + 0 + "px,0)";
            }

            if (_blackOverlay) {
              _blackOverlay.style.opacity = "0";
            }
          } else {
            if (
              o.settings_mode != "oneelement" &&
              cthis.classList.contains("do-not-set-js-height") == false &&
              cthis.classList.contains("height-is-based-on-content") == false
            ) {
              cthis.style.height = initialheight + "px";
            }
          }
        }
        if (_theTarget.getAttribute("data-forcewidth_ratio")) {
          _theTarget.style.width =
            Number(_theTarget.getAttribute("data-forcewidth_ratio")) *
              _theTarget.offsetHeight +
            "px";
          if (_theTarget.offsetWidth < cthis.offsetWidth) {
            _theTarget.style.width = cthis.offsetWidth + "px";
          }
        }

        clearTimeout(inter_scroll_from_resize);
        inter_scroll_from_resize = setTimeout(handle_scroll, 200);
      }

      function handle_mousemove(e) {
        if (o.settings_mode == "mouse_body") {
          st = e.pageY;
          var vi_y = 0;

          if (th == 0) {
            return;
          }

          vi_y = (st / wh) * (ch - th);
          bo_o = st / ch;

          if (vi_y > 0) {
            vi_y = 0;
          }
          if (vi_y < ch - th) {
            vi_y = ch - th;
          }
          if (bo_o > 1) {
            bo_o = 1;
          }
          if (bo_o < 0) {
            bo_o = 0;
          }

          finish_viy = vi_y;
        }

        if (o.settings_movexaftermouse == "on") {
          var mx = e.pageX;
          var vi_x = 0;

          vi_x = (mx / ww) * (cw - tw);

          if (vi_x > 0) {
            vi_x = 0;
          }
          if (vi_x < cw - tw) {
            vi_x = cw - tw;
          }

          finish_vix = vi_x;
        }
      }

      function handle_scroll(e, pargs) {
        st = window.pageYOffset || document.documentElement.scrollTop;
        vi_y = 0;

        if (
          (cthis_ot > st - wh && st < cthis_ot + cthis.offsetHeight) ||
          o.mode_scroll == "fromtop"
        ) {
          sw_out_of_display = false;
          sw_suspend_functional = false;
        } else {
          if (o.settings_detect_out_of_screen) {
            sw_out_of_display = true;
            sw_suspend_functional = true;
          }
        }

        if (_scrollTop_is_another_element_top) {
          st = -parseInt(_scrollTop_is_another_element_top.style.top, 10);
          if (_scrollTop_is_another_element_top.dataset.targettop) {
            st = -_scrollTop_is_another_element_top.dataset.targettop;
          }
        }

        if (o.settings_listen_to_object_scroll_top) {
          st = o.settings_listen_to_object_scroll_top.val;
        }

        if (isNaN(st)) {
          st = 0;
        }

        if (e) {
          if (o.init_functional_remove_delay_on_scroll == "on") {
            sw_suspend_functional = false;
          }
        }

        var margs = {
          force_vi_y: null,
          from: "",
          force_ch: null,
          force_th: null,
        };

        if (pargs) {
          margs = Object.assign(margs, pargs);
        }

        if (o.settings_clip_height_is_window_height) {
          ch = window.innerHeight;
        }

        if (margs.force_ch != null) {
          ch = margs.force_ch;
        }
        if (margs.force_th != null) {
          th = margs.force_th;
        }

        if (started === false) {
          wh = window.innerHeight;
          if (st + wh >= cthis.offsetTop) {
            init_start();
          }
        }

        if (th == 0) {
          return;
        }

        if (
          started === false ||
          (o.settings_mode != "scroll" && o.settings_mode != "oneelement")
        ) {
          return;
        }

        if (o.settings_mode == "oneelement") {
          var aux_r = (st - cthis_ot + wh) / wh;

          if (aux_r < 0) {
            aux_r = 0;
          }
          if (aux_r > 1) {
            aux_r = 1;
          }

          if (o.direction == "reverse") {
            aux_r = Math.abs(1 - aux_r);
          }

          vi_y =
            aux_r * (o.settings_mode_oneelement_max_offset * 2) -
            o.settings_mode_oneelement_max_offset;

          finish_viy = vi_y;
        }

        if (o.settings_mode == "scroll") {
          if (o.mode_scroll == "fromtop") {
            vi_y = (st / ch) * (ch - th);

            if (o.is_fullscreen == "on") {
              vi_y = (st / (document.body.offsetHeight - wh)) * (ch - th);

              if (_scrollTop_is_another_element_top) {
                vi_y =
                  (st / (_scrollTop_is_another_element_top.offsetHeight - wh)) *
                  (ch - th);
              }
            }

            if (o.direction == "reverse") {
              vi_y = (1 - st / ch) * (ch - th);

              if (o.is_fullscreen == "on") {
                vi_y = (1 - st / (document.body.offsetHeight - wh)) * (ch - th);

                if (_scrollTop_is_another_element_top) {
                  vi_y =
                    (1 -
                      st /
                        (_scrollTop_is_another_element_top.offsetHeight - wh)) *
                    (ch - th);
                }
              }
            }
          }
          cthis_ot = cthis.getBoundingClientRect().top;

          if (_scrollTop_is_another_element_top) {
            cthis_ot = -parseInt(
              _scrollTop_is_another_element_top.style.top,
              10
            );
          }

          var auxer5 =
            (st - (cthis_ot - wh)) / (cthis_ot + ch - (cthis_ot - wh));

          if (o.is_fullscreen == "on") {
            auxer5 = st / (document.body.offsetHeight - wh);

            if (_scrollTop_is_another_element_top) {
              auxer5 =
                st / (_scrollTop_is_another_element_top.offsetHeight - wh);
            }
          }

          if (auxer5 > 1) {
            auxer5 = 1;
          }
          if (auxer5 < 0) {
            auxer5 = 0;
          }

          if (animator_objects_arr) {
            for (i = 0; i < animator_objects_arr.length; i++) {
              var _c = animator_objects_arr[i];
              var cdata = _c.dataset.parallaxOptions;

              if (cdata) {
                for (var j = 0; j < cdata.length; j++) {
                  if (auxer5 <= 0.5) {
                    var auxer5_doubled = auxer5 * 2;
                    var auxer5_doubled_inverse = auxer5 * 2 - 1;
                    if (auxer5_doubled_inverse < 0) {
                      auxer5_doubled_inverse = -auxer5_doubled_inverse;
                    }

                    var auxval =
                      auxer5_doubled_inverse * cdata[j].initial +
                      auxer5_doubled * cdata[j].mid;
                    var cval = cdata[j].value;

                    cval = cval.replace("{{val}}", auxval);
                    _c.style[cdata[j].property] = cval;
                  } else {
                    var auxer5_doubled = (auxer5 - 0.5) * 2;
                    var auxer5_doubled_inverse = auxer5_doubled - 1;
                    if (auxer5_doubled_inverse < 0) {
                      auxer5_doubled_inverse = -auxer5_doubled_inverse;
                    }

                    var auxval =
                      auxer5_doubled_inverse * cdata[j].mid +
                      auxer5_doubled * cdata[j].final;
                    var cval = cdata[j].value;
                    cval = cval.replace("{{val}}", auxval);
                    _c.style[cdata[j].property] = cval;
                  }
                }
              }
            }
          }

          if (o.mode_scroll == "normal") {
            vi_y = auxer5 * (ch - th);

            if (o.direction == "reverse") {
              vi_y = (1 - auxer5) * (ch - th);
            }
          }

          if (cthis.classList.contains("simple-parallax")) {
            aux_r = (st + wh - cthis_ot) / (wh + th);

            if (aux_r < 0) {
              aux_r = 0;
            }
            if (aux_r > 1) {
              aux_r = 1;
            }

            aux_r = Math.abs(1 - aux_r);

            if (cthis.classList.contains("is-mobile")) {
              simple_parallaxer_max_offset = cthis.offsetHeight / 2;
            }

            vi_y =
              aux_r * (simple_parallaxer_max_offset * 2) -
              simple_parallaxer_max_offset;
          }

          if (_fadeouttarget) {
            var auxer4 = Math.abs((st - cthis_ot) / cthis.offsetHeight - 1);
            if (auxer4 > 1) {
              auxer4 = 1;
            }
            if (auxer4 < 0) {
              auxer4 = 0;
            }

            _fadeouttarget.style.opacity = auxer4;
          }

          bo_o = st / ch;

          if (cthis.classList.contains("simple-parallax") == false) {
            if (vi_y > 0) {
              vi_y = 0;
            }
            if (vi_y < ch - th) {
              vi_y = ch - th;
            }
          }

          if (bo_o > 1) {
            bo_o = 1;
          }
          if (bo_o < 0) {
            bo_o = 0;
          }

          if (margs.force_vi_y) {
            vi_y = margs.force_vi_y;
          }

          finish_viy = vi_y;
          finish_bo = bo_o;

          if (duration_viy === 0) {
            target_viy = finish_viy;

            if (sw_suspend_functional == false || 0) {
              if (cthis.classList.contains("simple-parallax")) {
                if (
                  _theTarget.parentNode.classList.contains("is-image") ||
                  cthis.classList.contains("simple-parallax--is-only-image")
                ) {
                  _theTarget.style.backgroundPositionY =
                    "calc(50% - " + parseInt(target_viy, 10) + "px)";
                }
              } else {
                if (is_ie() && version_ie() <= 10) {
                  _theTarget.style.top = "" + target_viy + "px";
                } else {
                  _theTarget.style.transform =
                    "translate3d(" + target_vix + "px," + target_viy + "px,0)";

                  if (o.settings_mode == "oneelement") {
                    cthis.style.transform =
                      "translate3d(" +
                      target_vix +
                      "px," +
                      target_viy +
                      "px,0)";
                  }
                }
              }
            }
          }
        }

        if (sw_suspend_functional) {
          requestAnimFrame(handle_frame);
          return false;
        }

        if (isNaN(target_viy)) {
          target_viy = 0;
        }

        if (debug_var) {
          debug_var = false;
        }

        if (duration_viy === 0) {
          if (api_outer_update_func) {
            api_outer_update_func(target_viy);
          }
          requestAnimFrame(handle_frame);
          return false;
        }

        begin_viy = target_viy;
        change_viy = finish_viy - begin_viy;

        begin_bo = target_bo;
        change_bo = finish_bo - begin_bo;

        if (o.easing == "easeIn") {
          target_viy = Number(
            Math.easeIn(1, begin_viy, change_viy, duration_viy).toFixed(5)
          );
          target_bo = Number(
            Math.easeIn(1, begin_bo, change_bo, duration_viy).toFixed(5)
          );
        }
        if (o.easing == "easeOutQuad") {
          target_viy = Math.easeOutQuad(1, begin_viy, change_viy, duration_viy);
          target_bo = Math.easeOutQuad(1, begin_bo, change_bo, duration_viy);
        }
        if (o.easing == "easeInOutSine") {
          target_viy = Math.easeInOutSine(
            1,
            begin_viy,
            change_viy,
            duration_viy
          );
          target_bo = Math.easeInOutSine(1, begin_bo, change_bo, duration_viy);
        }

        target_vix = 0;
        if (o.settings_movexaftermouse == "on") {
          begin_vix = target_vix;
          change_vix = finish_vix - begin_vix;
          target_vix = Math.easeIn(1, begin_vix, change_vix, duration_viy);
        }

        if (cthis.classList.contains("simple-parallax")) {
          if (_theTarget.parentNode.classList.contains("is-image")) {
            _theTarget.style.backgroundPositionY =
              "calc(50% - " + parseInt(target_viy, 10) + "px)";
          }
        } else {
          if (is_ie() && version_ie() <= 10) {
            _theTarget.style.top = "" + target_viy + "px";
          } else {
            _theTarget.style.transform =
              "translate3d(" + target_vix + "px," + target_viy + "px,0)";

            if (o.settings_mode == "oneelement") {
              cthis.style.transform =
                "translate3d(" + target_vix + "px," + target_viy + "px,0)";
            }
          }
        }

        if (_blackOverlay) {
          _blackOverlay.style.opacity = target_bo;
        }

        if (api_outer_update_func) {
          api_outer_update_func(target_viy);
        }

        if (stop_enter_frame) {
          return false;
        }

        requestAnimFrame(handle_frame);
      }

      function handle_frame() {
        if (sw_suspend_functional) {
          requestAnimFrame(handle_frame);
          return false;
        }

        if (isNaN(target_viy)) {
          target_viy = 0;
        }

        if (debug_var) {
          debug_var = false;
        }

        if (duration_viy === 0) {
          if (api_outer_update_func) {
            api_outer_update_func(target_viy);
          }
          requestAnimFrame(handle_frame);
          return false;
        }

        begin_viy = target_viy;
        change_viy = finish_viy - begin_viy;

        begin_bo = target_bo;
        change_bo = finish_bo - begin_bo;

        if (o.easing == "easeIn") {
          target_viy = Number(
            Math.easeIn(1, begin_viy, change_viy, duration_viy).toFixed(5)
          );
          //target_viy =  Number(Math.easeIn(1, begin_viy, change_viy, duration_viy));
          target_bo = Number(
            Math.easeIn(1, begin_bo, change_bo, duration_viy).toFixed(5)
          );

          // console.info(target_viy);
        }
        if (o.easing == "easeOutQuad") {
          target_viy = Math.easeOutQuad(1, begin_viy, change_viy, duration_viy);
          target_bo = Math.easeOutQuad(1, begin_bo, change_bo, duration_viy);
        }
        if (o.easing == "easeInOutSine") {
          target_viy = Math.easeInOutSine(
            1,
            begin_viy,
            change_viy,
            duration_viy
          );
          target_bo = Math.easeInOutSine(1, begin_bo, change_bo, duration_viy);
        }

        target_vix = 0;
        if (o.settings_movexaftermouse == "on") {
          begin_vix = target_vix;
          change_vix = finish_vix - begin_vix;
          target_vix = Math.easeIn(1, begin_vix, change_vix, duration_viy);
        }

        //console.log(begin_viy, change_viy, target_viy);

        // console.info('DURATION VIY = many', duration_viy, vi_y, target_viy)

        if (cthis.hasClass("simple-parallax")) {
          if (_theTarget.parent().hasClass("is-image")) {
            //_theTarget.css('background-position-y',target_viy+'px')
            _theTarget.css(
              "background-position-y",
              "calc(50% - " + parseInt(target_viy, 10) + "px)"
            );
          }
        } else {
          if (is_ie() && version_ie() <= 10) {
            _theTarget.css("top", "" + target_viy + "px");
          } else {
            _theTarget.css(
              "transform",
              "translate3d(" + target_vix + "px," + target_viy + "px,0)"
            );

            if (o.settings_mode == "oneelement") {
              cthis.css(
                "transform",
                "translate3d(" + target_vix + "px," + target_viy + "px,0)"
              );
            }
          }
        }

        //console.info(_blackOverlay,target_bo);;

        if (_blackOverlay) {
          _blackOverlay.css("opacity", target_bo);
        }

        if (api_outer_update_func) {
          api_outer_update_func(target_viy);
        }

        if (stop_enter_frame) {
          return false;
        }

        requestAnimFrame(handle_frame);
      }
    });
  }

  function dzsprx_init(selector, settings) {
    if (
      typeof settings != "undefined" &&
      typeof settings.init_each != "undefined" &&
      settings.init_each == true
    ) {
      var element_count = 0;
      for (var e in settings) {
        element_count++;
      }
      if (element_count == 1) {
        settings = undefined;
      }

      document.querySelectorAll(selector).forEach(function (_t) {
        dzsparallaxer(settings);
      });
    } else {
      dzsparallaxer(settings);
    }
  }

  window.dzsprx_init = dzsprx_init;
})();

function requestAnimFrame() {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
}

window.requestAnimFrame = requestAnimFrame();

function is_mobile() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return true;
  }

  if (/android/i.test(userAgent)) {
    return true;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  return false;
}

function is_touch_device() {
  return !!("ontouchstart" in window);
}

window.requestAnimFrame = (function () {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

function is_ie() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }

  var trident = ua.indexOf("Trident/");
  if (trident > 0) {
    var rv = ua.indexOf("rv:");
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  }

  var edge = ua.indexOf("Edge/");
  if (edge > 0) {
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
  }

  return false;
}

function is_ie11() {
  return !window.ActiveXObject && "ActiveXObject" in window;
}

function version_ie() {
  return parseFloat(navigator.appVersion.split("MSIE")[1]);
}
document.addEventListener("DOMContentLoaded", function () {
  document
    .querySelectorAll(".dzsparallaxer---window-height")
    .forEach(function (_t) {
      window.addEventListener("resize", handle_resize);
      handle_resize();

      function handle_resize() {
        var wh = window.innerHeight;
        _t.style.height = wh + "px";
      }
    });

  dzsprx_init(".dzsparallaxer.auto-init", { init_each: true });
});
